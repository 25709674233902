"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initializePolls = void 0;
// internal types
const internal_1 = require("../types/internal");
// internal utils
const config_1 = require("../utils/config");
const constants_1 = require("../utils/constants");
const error_1 = require("../utils/error");
/**
 * Initialize the Polls SDK.
 * Must be performed before interacting with the SDK.
 * Any attempts to interact with the SDK before initializing will result
 * in an error thrown.
 * The SDK can be initialized multiple times and will always used the latest config.
 * @category 1. Initialization
 * @param config
 */
const initializePolls = (config) => {
    if (!(0, internal_1.isConfig)(config)) {
        throw (0, error_1.createError)(constants_1.BAD_CONFIG_MESSAGE);
    }
    (0, config_1.setConfig)(config);
};
exports.initializePolls = initializePolls;
