"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BAD_CONFIG_MESSAGE = exports.INIT_REQUIRED_MESSAGE = exports.REFRESH_POLL_AGE_SECONDS = exports.ANIMATION_DURATION = exports.DRAFT_POLL_PATH = exports.POLL_PATH = exports.QA_DOMAIN_SUFFIX = exports.WEBVIEW_ORIGIN_WHITELIST = exports.ERROR_PREFIX = exports.TOP_LEVEL_DOMAIN = exports.DEFAULT_URL_SCHEME = exports.API_BASE_URL = void 0;
const API_BASE_URL = "https://polls-api.com/partner/v1";
exports.API_BASE_URL = API_BASE_URL;
const DEFAULT_URL_SCHEME = "https://";
exports.DEFAULT_URL_SCHEME = DEFAULT_URL_SCHEME;
const TOP_LEVEL_DOMAIN = "pollsplatform.com";
exports.TOP_LEVEL_DOMAIN = TOP_LEVEL_DOMAIN;
const ERROR_PREFIX = "[Polls Platform] ";
exports.ERROR_PREFIX = ERROR_PREFIX;
const WEBVIEW_ORIGIN_WHITELIST = ["https://*.pollsplatform.com", "http://*.pollsplatform.com"];
exports.WEBVIEW_ORIGIN_WHITELIST = WEBVIEW_ORIGIN_WHITELIST;
const QA_DOMAIN_SUFFIX = "-qa";
exports.QA_DOMAIN_SUFFIX = QA_DOMAIN_SUFFIX;
const POLL_PATH = "polls";
exports.POLL_PATH = POLL_PATH;
const DRAFT_POLL_PATH = "poll-drafts";
exports.DRAFT_POLL_PATH = DRAFT_POLL_PATH;
const ANIMATION_DURATION = 250;
exports.ANIMATION_DURATION = ANIMATION_DURATION;
const REFRESH_POLL_AGE_SECONDS = 60 * 60 * 24 * 30; // 1 month ------ 60 * 3; // 3 minutes
exports.REFRESH_POLL_AGE_SECONDS = REFRESH_POLL_AGE_SECONDS;
// error messages
const INIT_REQUIRED_MESSAGE = "attempted to use the SDK before initializing";
exports.INIT_REQUIRED_MESSAGE = INIT_REQUIRED_MESSAGE;
const BAD_CONFIG_MESSAGE = "config does not match required schema";
exports.BAD_CONFIG_MESSAGE = BAD_CONFIG_MESSAGE;
