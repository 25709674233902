"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isOptionResponse = exports.isConfig = exports.isAnalyticsParameters = exports.isAnalyticsEvent = exports.isCreatePollResult = exports.EmbeddedMessageEvent = exports.PollUrlParams = exports.EmbeddedState = void 0;
// core
const __1 = require("../..");
// internal utils
const schemaValidation_1 = require("../../utils/schemaValidation");
var EmbeddedState;
(function (EmbeddedState) {
    EmbeddedState["NONE"] = "none";
    EmbeddedState["SDK_SIDEBAR"] = "sdk-sidebar";
    EmbeddedState["SDK_MODAL"] = "sdk-modal";
    EmbeddedState["EXTENSION_SIDEBAR"] = "extension-sidebar";
})(EmbeddedState || (EmbeddedState = {}));
exports.EmbeddedState = EmbeddedState;
var PollUrlParams;
(function (PollUrlParams) {
    PollUrlParams["share"] = "share";
    PollUrlParams["pending"] = "pending";
    PollUrlParams["embeddedState"] = "embeddedState";
    PollUrlParams["theme"] = "theme";
})(PollUrlParams || (PollUrlParams = {}));
exports.PollUrlParams = PollUrlParams;
var EmbeddedMessageEvent;
(function (EmbeddedMessageEvent) {
    EmbeddedMessageEvent["VIEW_OPTION_DETAILS"] = "viewOptionDetails";
    EmbeddedMessageEvent["ANALYTICS_EVENT"] = "analyticsEvent";
})(EmbeddedMessageEvent || (EmbeddedMessageEvent = {}));
exports.EmbeddedMessageEvent = EmbeddedMessageEvent;
const isCreatePollResult = (object) => {
    return ((0, schemaValidation_1.validateProperty)({
        object,
        property: "data",
        type: schemaValidation_1.PropertyType.object,
        optional: false,
    }) &&
        (0, schemaValidation_1.validateProperty)({
            object: object.data,
            property: "poll",
            type: schemaValidation_1.PropertyType.object,
            optional: false,
        }) &&
        (0, schemaValidation_1.validateProperty)({
            object: object.data.poll,
            property: "url",
            type: schemaValidation_1.PropertyType.string,
            optional: false,
        }));
};
exports.isCreatePollResult = isCreatePollResult;
const isAnalyticsParameters = (object) => {
    return (typeof object === "object" &&
        object !== null &&
        !Object.keys(object).find((key) => {
            return (
            // fail if key is not string
            typeof key !== schemaValidation_1.PropertyType.string ||
                // fail if value is not string, not number, not boolean, not undefined
                (typeof object[key] !== schemaValidation_1.PropertyType.string &&
                    typeof object[key] !== schemaValidation_1.PropertyType.number &&
                    typeof object[key] !== schemaValidation_1.PropertyType.boolean &&
                    object[key]));
        }));
};
exports.isAnalyticsParameters = isAnalyticsParameters;
const isAnalyticsEvent = (object) => {
    return ((0, schemaValidation_1.validateProperty)({
        object,
        property: "event",
        type: schemaValidation_1.PropertyType.string,
        optional: false,
    }) &&
        (0, schemaValidation_1.validateProperty)({
            object,
            property: "parameters",
            type: schemaValidation_1.PropertyType.object,
            optional: true,
        }) &&
        (!object.parameters ||
            isAnalyticsParameters(object.parameters)));
};
exports.isAnalyticsEvent = isAnalyticsEvent;
const isConfig = (object) => {
    return ((0, schemaValidation_1.validateProperty)({
        object,
        property: "apiKey",
        type: schemaValidation_1.PropertyType.string,
        optional: false,
    }) &&
        (0, schemaValidation_1.validateProperty)({
            object,
            property: "domainConfig",
            type: schemaValidation_1.PropertyType.object,
            optional: false,
        }) &&
        isDomainConfig(object.domainConfig) &&
        (0, schemaValidation_1.validateProperty)({
            object,
            property: "environment",
            type: schemaValidation_1.PropertyType.string,
            optional: true,
        }) &&
        (!object.environment ||
            Object.values(__1.Environment).includes(object.environment)));
};
exports.isConfig = isConfig;
const isDomainConfig = (object) => {
    return isSubdomainConfig(object);
};
const isSubdomainConfig = (object) => {
    return ((0, schemaValidation_1.validateProperty)({
        object,
        property: "type",
        type: schemaValidation_1.PropertyType.string,
        optional: false,
    }) &&
        object.type === __1.DomainConfigType.subdomain &&
        (0, schemaValidation_1.validateProperty)({
            object,
            property: "subdomain",
            type: schemaValidation_1.PropertyType.string,
            optional: false,
        }));
};
const isOptionResponse = (object) => {
    return ((0, schemaValidation_1.validateProperty)({
        object,
        property: "id",
        type: schemaValidation_1.PropertyType.string,
        optional: false,
    }) &&
        (0, schemaValidation_1.validateProperty)({
            object,
            property: "url",
            type: schemaValidation_1.PropertyType.string,
            optional: false,
        }) &&
        (0, schemaValidation_1.validateProperty)({
            object,
            property: "resourceId",
            type: schemaValidation_1.PropertyType.string,
            optional: true,
        }));
};
exports.isOptionResponse = isOptionResponse;
