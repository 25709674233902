"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getThemeFromPollUrl = exports.setPollUrlParams = void 0;
// internal types
const internal_1 = require("../types/internal");
const setQueryParam = (options) => {
    let { urlString } = options;
    const { key, value } = options;
    if (!urlString.includes("?")) {
        // does not include query string,
        // e.g. https://example.com/
        urlString = `${urlString}?`;
        // updated to https://example.com/?
    }
    else if (!urlString.endsWith("?")) {
        // includes query string and parameters
        // e.g. https://example.com/?key=value
        urlString = `${urlString}&`;
        // updated to https://example.com/?key=value&
    }
    else {
        // includes empty query string
        // e.g. https://example.com/?
        // ready to go
    }
    // TODO: we are not encoding these for now since they are only used
    // internally with safe keys and values
    urlString = `${urlString}${key}=${value}`;
    return urlString;
};
const setPollUrlParams = (urlString, options) => {
    const { share, pending, theme, embeddedState } = options;
    // pending
    if (pending === undefined) {
        // do nothing if not provided
    }
    else if (pending) {
        urlString = setQueryParam({
            urlString,
            key: internal_1.PollUrlParams.pending,
            value: "true",
        });
    }
    else {
        // TODO: delete param just to be safe
        // url.searchParams.delete(PollUrlParams.pending);
    }
    // share
    if (share === undefined) {
        // do nothing if not provided
    }
    else if (share) {
        urlString = setQueryParam({
            urlString,
            key: internal_1.PollUrlParams.share,
            value: "true",
        });
    }
    else {
        // TODO: delete param just to be safe
        // url.searchParams.delete(PollUrlParams.share);
    }
    // theme
    if (theme === undefined) {
        // do nothing if not provided
    }
    else if (theme) {
        urlString = setQueryParam({
            urlString,
            key: internal_1.PollUrlParams.theme,
            value: theme,
        });
    }
    else {
        // TODO: delete param just to be safe
        // url.searchParams.delete(PollUrlParams.theme);
    }
    // embeddedState
    if (embeddedState === undefined) {
        // do nothing if not provided
    }
    else if (embeddedState === internal_1.EmbeddedState.NONE) {
        urlString = setQueryParam({
            urlString,
            key: internal_1.PollUrlParams.embeddedState,
            value: embeddedState,
        });
    }
    else {
        // TODO: delete param just to be safe
        // url.searchParams.set(PollUrlParams.embeddedState, embeddedState);
    }
    return urlString;
};
exports.setPollUrlParams = setPollUrlParams;
const getThemeFromPollUrl = (url) => {
    return new URL(url).searchParams.get(internal_1.PollUrlParams.theme);
};
exports.getThemeFromPollUrl = getThemeFromPollUrl;
