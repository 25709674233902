"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PropertyType = exports.validateProperty = void 0;
var PropertyType;
(function (PropertyType) {
    PropertyType["object"] = "object";
    PropertyType["string"] = "string";
    PropertyType["number"] = "number";
    PropertyType["boolean"] = "boolean";
})(PropertyType || (PropertyType = {}));
exports.PropertyType = PropertyType;
const validateProperty = (options) => {
    const { object, property, type, optional } = options;
    return (typeof object === "object" &&
        object !== null &&
        ((optional && !(property in object)) ||
            (property in object && typeof object[property] === type)));
};
exports.validateProperty = validateProperty;
