"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Environment = exports.DomainConfigType = void 0;
/**
 * default: production
 * @category 1. Initialization
 */
var Environment;
(function (Environment) {
    Environment["qa"] = "qa";
    Environment["production"] = "production";
})(Environment || (Environment = {}));
exports.Environment = Environment;
/**
 * When using subdomain config, polls will be hosted at `{subdomain}.pollsplatform.com`
 * @category 1. Initialization
 */
var DomainConfigType;
(function (DomainConfigType) {
    DomainConfigType["subdomain"] = "subdomain";
})(DomainConfigType || (DomainConfigType = {}));
exports.DomainConfigType = DomainConfigType;
