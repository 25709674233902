"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OpenPollFlow = exports.OptionType = exports.PostVoteAction = exports.PollState = void 0;
/**
 * The poll state determines if the poll will be live instantly,
 * or if the user will be able to edit before publishing
 * default: `live`
 * @category 2. Create Poll
 */
var PollState;
(function (PollState) {
    /**
     * Draft polls can still be opened in the browser or in a <PollView> component.
     * If the poll state is set to draft, the user will be given the option of editing the poll
     * before publishing. For example the user can select which of the options they want to include.
     */
    PollState["draft"] = "draft";
    /**
     * Live polls are instantly published and cannot be edited.
     */
    PollState["live"] = "live";
})(PollState || (PollState = {}));
exports.PollState = PollState;
/**
 * This enum determines what happens after users submit their vote.
 * See the description of each enum value for details.
 * If you would like a different post-vote behavior, please contact support.
 * @category 2. Create Poll
 */
var PostVoteAction;
(function (PostVoteAction) {
    /**
     * if set to none, after voting users will simply see a confirmation check mark
     */
    PostVoteAction["none"] = "none";
    /**
     * if set to shareVote, after voting users will be prompted to share their vote back to the group
     */
    PostVoteAction["shareVote"] = "shareVote";
})(PostVoteAction || (PostVoteAction = {}));
exports.PostVoteAction = PostVoteAction;
/**
 * defines the fields and visual layout of the option cards
 * @category 2. Create Poll
 */
var OptionType;
(function (OptionType) {
    OptionType["defaultTemplate"] = "defaultTemplate";
})(OptionType || (OptionType = {}));
exports.OptionType = OptionType;
/**
 * Enum used to determine if a user is creating a poll or opening one
 * @category 3. PollView
 */
var OpenPollFlow;
(function (OpenPollFlow) {
    OpenPollFlow["createPoll"] = "createPoll";
    OpenPollFlow["openPoll"] = "openPoll";
})(OpenPollFlow || (OpenPollFlow = {}));
exports.OpenPollFlow = OpenPollFlow;
