"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getConfig = exports.setConfig = void 0;
// core
const __1 = require("..");
// internal utils
const constants_1 = require("../utils/constants");
const error_1 = require("../utils/error");
const defaultConfig = {
    environment: __1.Environment.production,
};
let currentConfig;
const setConfig = (config) => {
    currentConfig = Object.assign(Object.assign({}, defaultConfig), config);
};
exports.setConfig = setConfig;
const getConfig = () => {
    if (!currentConfig) {
        throw (0, error_1.createError)(constants_1.INIT_REQUIRED_MESSAGE);
    }
    return currentConfig;
};
exports.getConfig = getConfig;
